<template>
  <div class="page">
    <van-sticky>
      <van-search placeholder="试试输入课程标题或专家名字吧" @click="shamSear" />
    </van-sticky>
    <van-pull-refresh v-model="pullRefresh" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="加载失败，点击重新加载"
        @load="onLoad"
      >
        <!-- <div
          class="ask_home"
          v-for="(item, i) in list"
          :key="'ask' + i"
          @click="goToExpertDetails(item.autoid, item.userid)"
        >
          <div class="ask_home_flex">
            <div class="ask_home_flex_left">
              <img :src="item.headurl" alt />
            </div>
            <div>
              <div class="teacher_name">{{ item.name }}</div>
              <div class="people">{{ item.follownum }}人收听</div>
              <div class="ask_home_flex_zhong">
                <span class="people">教师简介:</span>
                <span class="synopsis">{{ item.describe }}</span>
              </div>
            </div>
            <div v-if="isLogin">
              <div
                :class="{
                  ask_home_flex_rights: item.isfollow,
                  ask_home_flex_right: !item.isfollow,
                }"
                @click.stop="changeFocus(item.userid, item.isfollow, i)"
                v-text="item.isfollow ? '已收听' : '未收听'"
              ></div>
            </div>
            <div v-else style="width: 16vw;height: 8vw;"></div>
          </div>
        </div>-->

        <ExpertList :list="list" @click="goToExpertDetails" />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { /*changeFocus,*/ getTeacherList } from '../api/questionsAndAnswers'
import ExpertList from '@/components/extend/expertList'

export default {
  components: {
    ExpertList
  },
  data() {
    return {
      // key: "",
      userId: this.$store.state.user.userId,
      // status: true,
      page: 1, // 当前页数
      list: [], // 显示数据
      pullRefresh: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 全部加载完成
      error: false // 加载出现错误
    }
  },
  computed: {
    isLogin() {
      return this.$store.getters.isLogin
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.list = []
      this.page = 1
      this.finished = true //ui框架问题，非finished=true，onRefresh的时候不会触发onLoad
      this.$nextTick(() => {
        this.finished = false
      })
    },
    // 底部加载
    onLoad() {
      getTeacherList(this.userId, this.page)
        .then(res => {
          this.loading = false
          this.list.push(...res.list)
          this.pullRefresh = false
          if (this.page * 10 >= res.sum) {
            this.finished = true
          } else {
            this.page += 1
          }
        })
        .catch(() => {
          this.loading = false
          this.pullRefresh = false
          this.error = true
        })
    },
    // 搜索
    shamSear() {
      this.$router.push({
        path: '/classSearch'
      })
    },
    // ->专家详情
    goToExpertDetails(item) {
      this.$router.push({
        path: '/expertDetails/' + item.autoid,
        query: {
          expertUserId: item.userid //专家的用户id
        }
      })
    }
    // // 改变关注状态
    // changeFocus(expertId, type, i) {
    //   const t = type ? 0 : 1
    //   changeFocus(this.userId, expertId, t).then(res => {
    //     this.list[i].isfollow = !this.list[i].isfollow
    //   })
    // }
  }
}
</script>

<style scoped>
.page::-webkit-scrollbar {
  display: none;
}
.ask_home_flex_zhong {
  display: flex;
  align-items: center;
}

.synopsis {
  display: block;
  font-size: 3.467vw;
  color: #303133;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 40vw;
}

.people {
  font-size: 3.467vw;
  color: #909399;
}

.teacher_name {
  font-size: 3.733vw;
  color: #303133;
}

.ask_home_flex_right {
  width: 16vw;
  height: 8vw;
  background-color: #ffffff;
  border-radius: 4vw;
  border: solid 0.267vw #247dff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.467vw;
  color: #247dff;
  margin-bottom: 6vw;
}

.ask_home_flex_rights {
  width: 16vw;
  height: 8vw;
  background-color: #f5f7fa;
  border-radius: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.467vw;
  color: #303133;
  margin-bottom: 6vw;
}

.ask_home_flex_left {
  width: 15.467vw;
  height: 15.467vw;
  background-color: #a2a6a7;
  border-radius: 100%;
}
.ask_home_flex_left > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.ask_home_flex {
  width: 93.733vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ask_home {
  width: 100vw;
  height: 27.467vw;
  border-bottom: 3vw solid #f5f7fa;
  display: flex;
  align-items: center;
}
</style>
